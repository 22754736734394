import React, { useState, useEffect } from 'react';
import { getHash } from '~/utils';
import { globalStore } from '~/store';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
const GeoLookup = ({ ip }) => {
  const [geoData, setGeoData] = useState({ hash: '', data: null });

  useEffect(() => {
    const fetchIPInfo = async () => {
      try {
        const ipInfoResponse = await fetch(`https://pro.ip-api.com/json/${ip}?key=kYFQIG18eRxCaZS&fields=268173311`);
        const ipInfoData = await ipInfoResponse.json();

        if (ipInfoData.status === 'success') {
          const hashValue = await getHash(ipInfoData);
          setGeoData({ hash: hashValue, data: ipInfoData });
          globalStore.set({ ...globalStore.get(), geoip: { hash: hashValue, data: ipInfoData } });
        }
      } catch (error) {
        console.error('Error fetching IP information:', error);
      }
    };

    fetchIPInfo();
  }, []);
  return (
    <div>
      <h2 className="text-3xl font-medium mb-5 flex items-center gap-2">
        GeoLookup <span className="text-sm border px-1"> {geoData.hash.slice(0, 12)} </span>
      </h2>
      <div className="overflow-auto border p-2 rounded-lg border-slate-200 h-auto max-h-[500px]">
        <JsonView src={geoData} />
      </div>
    </div>
  );
};

export default GeoLookup;
